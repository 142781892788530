import { H1, Text1 } from "@betnbet/front-sdk/dist/Common/Title"
import { scrollToTop } from "@betnbet/front-sdk/dist/util/scrolls"
import Container from "@material-ui/core/Container"
import React, { FC, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useDi } from "../App/di"
import { ButtonLink } from "../common/button"

type TErrorCode = 404 | 500 | number
const errors: Record<TErrorCode, { ru: string; es: string }> = {
  404: { ru: "404 \n Страница не найдена", es: "404 \n Página no encontrada" },
  500: {
    ru: "500 \n Внутренняя ошибка сервера",
    es: "500 \n Error interno del servicio en la nube",
  },
}

type THttpErrorPage = {
  code: number
}

export const HttpErrorPage: FC<THttpErrorPage> = ({ code }) => {
  const { lang, lng } = useDi()
  const text = code in errors ? errors[code][lng] : lang.http_error_page__meta
  const is404 = code === 404

  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <Container>
      <Helmet>
        <title>{text}</title>
        <meta name="description" content={text} />
      </Helmet>
      <H1 style={{ whiteSpace: "pre-line", marginTop: 48 }}>{text}</H1>
      {is404 && (
        <Text1 style={{ marginTop: "20px" }}>
          {lang.http_error_page__text}
        </Text1>
      )}
      <ButtonLink
        to="/"
        size="large"
        style={{ marginTop: "40px", marginBottom: "200px" }}
      >
        {lang.http_error_page__to_home_page__button}
      </ButtonLink>
    </Container>
  )
}

export const NotFound: FC = () => {
  console.error("NotFound")
  return <HttpErrorPage code={404} />
}
export const NotFoundError: FC = () => {
  console.error("NotFoundError")
  return <HttpErrorPage code={404} />
}
